.star-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 4em;
}

.on {
  color: #ED682F;
}
.off {
  color: #272727;
}