.table .btn {
  margin: 2px;
}

.new-expense-modal {
  min-width: 800px;
}

.dynamic-minus-button {
  margin-top: 28px;
}

.round-btn-add {
  margin-left: 12px;
}

.p-parcela {
  margin-top: 37px;
}

.font-badge {
  font-size: 13px;
}

@media (max-width: 768px) {
  .new-expense-modal {
    min-width: 100% !important;
  }
}

.boleto-modal {
  min-width: 1000px !important;
  /* min-height: 800px !important;  */
}

.check-liquidacao {
  margin-top: 20px;
}

.col-Stats {
  padding-left: 6px;
  padding-right: 6px;
}

.logo-attivilog {
  height: 40px;
}

.centralizarBotao {
  margin-top: 26px;
}

.nav-parent {
  margin: 10px 0 0 15px;
  display: block;
  position: relative;
  font-size: 12px;
  padding-top: 10px;
  line-height: 30px;
}

.nav-parent button {
  cursor: pointer;
}

.nav-parent-collapsed {
  height: 40px;
  margin-left: 5px !important;
}

.nav-parent ul {
  padding-left: 10px;
}

.nav-parent button {
  background-color: #212120;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  width: 95%;
}

.nav-parent button:hover {
  border: none;
  outline: none;
  width: 95%;
}

.nav-parent button:focus {
  border: none;
  outline: none;
}

.nav-parent button i {
  color: rgba(255, 255, 255, 0.6);
  float: left
}

.nav-parent button p {
  color: rgba(255, 255, 255, 0.6);
  float: left;
  font-weight: 400;
}

.nav-parent button:hover i, .nav-parent button:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.nav-parent .nav-link:hover p, .nav-parent .nav-link:hover i {
  color: rgba(255, 255, 255, 1);
}

#arrow {
  float: right;
  margin-right: 0px;
}

.arrow-rotate-minus-90 {
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.arrow-rotate-0 {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.expiration-icon {
  font-size: 18px;
  margin-right: 7px;
}

.color-yellow {
  color: rgb(163, 163, 1);
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.margin-top-30 {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

/* Login Screen */

.background-attivilog {
  background: linear-gradient(to bottom right, #ec6730, rgba(43, 16, 5, 0.5));
}

.background-white {
  background: white;
}

.alert-dark {
  background-color: #f04943 !important;
}

.center-panel {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -225px;
  margin-left: -200px;
  width: 400px;
  height: 450px;
  text-align: center;
  padding: 35px 50px;
  border-radius: 15px;
}

.center-panel input {
  margin: 25px 0;
}

.center-panel button {
  width: 100%;
  font-size: 15px;
}

.login-logo {
  font-size: 40px;
  display: block;
}

.app-login {
  position: relative;
}

.app-login p {
  display: inline-block;
}

.app-login img {
  height: 60px;
}

.logo-img {
  display: inline-block;
  margin-right: 15px;
  margin-top: -15px;
}

.login-text {
  font-size: 25px;
}

.hide {
  display: none;
}

.month-select {
  margin-left: auto;
  margin-right: auto;
}

.empresa-logo {
  width: 150px;
  height: 150px;
  margin: 10px auto;
  border: 2px solid #bfc4ca;
  position: relative;
}

.logo-placeholder {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.logo-edit {
  border: none;
  border-radius: 3px;
  background-color: silver;
  color: whitesmoke;
  position: absolute;
  bottom: 0;
  right: 0;
}

.logo-upload {
  width: 150px;
}

.dropzone-error {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}

.bg-gray {
  background-color: #cfd8dc;
  font-size: 12px;
}

.padding10 {
  padding: 10px;
}

.sidebar-wrapper {
  padding-bottom: 10px !important;
}

.sidebar-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 10px;
}

.sidebar-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.top-element div {
  z-index: 1060 !important;
}

.table-responsive {
  min-height: 300px !important;
}

.header-block {
  display: contents;
}

.header-greetings {
  float: left;
  margin-right: 20px !important;
}

.header-white {
  color: white !important;
}

.header-company-select {
  float: left;
  width: 400px;
  margin-right: 15px;
  
}

.company-select{
  max-height: 40px;
  overflow-y: auto;
}

.logout-button {
  background: none;
  border: none;
}

.logout-button:hover {
  color: #888;
}

.logout-button i {
  font-size: 20px;
}

.logout-button p {
  margin-left: 5px;
  font-size: 15px;
}

.disabled-select>div {
  background-color: #e3e3e3;
}

.disabled-select>div>div>div {
  color: #736e69;
}

.expanded-sidebar {
  width: 260px !important;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.collapsed-sidebar {
  width: 65px !important;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.menu-collapse {
  float: right;
  font-size: 20px;
  margin-top: 12px;
  margin-right: 10px;
}

.menu-expand {
  float: left;
  font-size: 20px;
  margin: 12px 10px 0 10px;
}

@media (max-width: 991px) {
  .expanded-sidebar {
    width: 260px !important;
  }

  .menu-collapse {
    display: none;
  }
  .menu-expande {
    display: none;
  }
}

.shrink-button {
  color: #ec6730;
  font-size: 20px;
  cursor: pointer;
}

.show-element {
  opacity: 1;
  transition: opacity 1.5s linear !important;
  position: inherit;
}

.hide-element {
  opacity: 0 !important;
  position: absolute !important;
}

.hide-tenant-home {
  opacity: 0;
  display: none;
}

.collapse-panel {
  width: calc(100% - 65px);
  height: 100%;
}

.routes-panel {
  transform: translate(0, -105px);
  -moz-transform: translate(0, -105px);
  -webkit-transform: translate(0, -105px);
  position: fixed;
  width: 225px;
  min-height: 50px;
  background-color: #2c2c2c;
  left: 64px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.routes-panel-normal-group {
  transform: translate(0, -40px);
  -moz-transform: translate(0, -40px);
  -webkit-transform: translate(0, -40px);
}

.collapsed-nav-link {
  display: block;
  margin: 15px 0 0 20px;
}

.collapsed-nav-link p {
  float: left;
  font-size: 14px;
  color: #e2e2e2;
  font-weight: 500;
  text-transform: uppercase;
}

.routes-group {
  margin: 10px 10px 10px 20px;
}

.routes-group p {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #e2e2e2;
}

.routes-group ul {
  padding-left: 0px;
}

.subroute-link {
  margin: 0 !important;
  padding: 0 !important;
}

.subroutes-separator {
  left: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: #e2e2e2;
}

.subroutes-group {
  margin-top: 10px;
}

.primary-blue {
  color: #007bff;
}

.bold {
  font-weight: bold;
}

.loadingCard {
  margin-top: 100px;
}

.agendada {
  color: #ff7f27;
}

.reset-col {
  flex: 1;
}

.main-panel {
  height: 100%;
}

.line-between-row {
  height: 25px;
}
.react-datepicker__input-container > input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
}

.react-datepicker-popper {
  width: max-content;
}

.input-group-addon {
  float: left;
}

.input-group-text {
  padding: 12px !important;
  color: #040404 !important;
  background-color: #e9ecef !important;
}

.remove-single-filter {
  padding: 4px;
  font-size: 10px;
}

.remove-single-filter:hover {
  font-size: 12px;
  padding: 3px;
}

.badgeAndamento-home-icon {
  margin-left: 9px !important;
  margin-top: 7px !important;
}

.badge-attivilog-red {
  background-color: red !important;
  color: white !important;
}

.tempoRealizadoEntrePontos {
  margin-top: 10px;
}

.modal-90w {
  width: 90%;
  max-width: none!important;
  margin-left: 6rem!important;
}

.time-select-fix-width {
  width: 100%;
}

.time-select-container {
  display: flex;
  flex-direction: row;
}

.react-datepicker-wrapper {
  width: 100%;
}

.table-no-results {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.table-no-results > img {
  width: 20%;
  padding-top: 35px;
}
.table-no-results > .empty-filter {
  position: absolute;
  top: 10%;
  text-align: center;
}
.table-no-results > .empty-filter > p {
  margin-bottom: 0.5rem;
}

.table-no-results > .empty-no-filter {
  position: absolute;
  top: 23%;
  text-align: center;
}

.custom-content {
  display: flex;
  flex-direction: column;
  margin-top: 63px;
  padding: 0 30px 20px;
  width: calc(100% - 65px);
  height: calc(100% - 63px - 25px);
}

.custom-content > div:nth-child(2) > div > div {
  height: 100%;
}

.custom-content > div:nth-child(2) {
  height: calc(100% - 58px);
}

.header-search-button {
  margin: 0;
}

.timeline-tracking-item {
  margin-left: 18px !important;
}

.text-tracking {
  margin-top: 15px !important;
}

.delivery-location-map {
  min-height: 500px !important;
  z-index: 500;
  margin-bottom: 2%;
}

.delivery-location-map > .leaflet-container {
  min-height: 500px !important;
}

.delivery-card-tracking {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.content-tracking {
  padding: 30px;
}

.align-left {
  align-items: baseline !important;
}

.separator-form {
  margin-top: 20px;
}

.search-events-button {
  margin: 0;
  height: 38px;
}

@media (max-width: 1452px) {
  .custom-width-col-9 {
    width: 9% !important;
  }
  .custom-width-col-14 {
    width: 14% !important;
  }
  .custom-width-col-12 {
    width: 12% !important;
  }
}


@media (min-width: 1453px) {
  .custom-width-col-10-larger {
    width: 10% !important;
  }
}

.custom-modal-footer {
  justify-content: flex-start;
}

@media(min-width: 768px) {
  .lati-longi-map-button {
    margin-top: 12%;
  }
}

.desconsider_person_input {
  margin-top: 7px;
  margin-bottom: 7px;
}

@media(min-width: 1200px) {
  .usuario-criacao-alteracao-style {
    width: 15%;
    flex: none;
  }  
}

@media(min-width: 768px) and (max-width: 1200px) {
  .usuario-criacao-alteracao-style {
    width: 30%;
    flex: none;
  }
  
  .usuario-criacao-alteracao-input {
    height: 32px;
    width: 180px;
  }
}

.origin-location-map {
  width: 750px;
  height: 200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  min-height: 200px !important;
}

.card-filtro-Relatorios {
  padding: 0px 15px 15px 15px;
}

.icon-card-filtro-Relatorios {
   display: inline; 
   padding: 0 11px;
   color: #EB672F;
   cursor: pointer;
}

.title-collapse-Relatorios {
  font-size: 14px; 
  display: inline;
}

.space-card-filter {
  margin: 10px 0px 10px;
}

@media (max-width: 576px) {
  .new-expense-modal {
    min-width: 100% !important;
    margin: 1.75rem auto;
  }
}

.tracking-content {
  width: 70%;
}

.w-auto{
  width: auto;
}

.content-count{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5vh;
  padding: 0.2vh 0.8vh;
  font-size: 1.4vh;
  font-weight: 600;
  border-radius: 0.5vh;
  background-color: rgba(var(--color), 0.2);
  color: rgba(var(--color), 1);
  white-space: pre-wrap;
}

.cursor-default {
  cursor: default !important;
}

.card-box-shadow {
  box-shadow: none !important;
}

