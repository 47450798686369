.route-maps-content {
  margin: 0;
  display: grid;
  padding: 0 !important;
}

.route-maps-panel {
  height: calc(100vh - 88px);
  margin: 0 !important;
}

.rotas-panel {
  width: 25%;
  height: 100%;
  float: left;
  background-color: #fff;
  color: #3c3c3b;
  border-radius: 10px;
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rotas-header {
  height: 25%;
  min-height: 160px;
  width: 100%;
  opacity: 1;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1.5px solid rgba(232, 102, 47, 0.7);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.routes-status {
  width: 100%;
  height: 60%;
}

.routes-content {
  height: 73%;
  width: 100%;
  overflow-y: auto;
}

.routes-status p {
  font-size: 15px;
}

.status-content {
  width: 100%;
  height: auto;
  min-height: 72px;
  display: flex;
  flex-direction: row;
}

.status-content > div:focus {
  outline: none;
}

.status-info {
  display: inline-block;
}

.status-info:hover {
  cursor: pointer;
  background-color: rgba(232, 102, 47, 0.4);
  border-radius: 13px;
}

.status-info:nth-child(1), .status-info:nth-child(2), .status-info:nth-child(3) {
  text-align: center;
}

.status-info i {
  display: inline-block;
  margin-right: 3px;
  font-size: 14px;
}

.status-info p {
  display: inline-block;
}

.routes-card {
  background-color: #fff;
  border-bottom: 1px solid #aaa;
  padding: 10px;
  cursor: pointer;
}

.routes-card:hover {
  background-color: rgba(232, 102, 47, 0.5)!important;
}

.routes-card p {
  margin: 3px;
}

.routes-card .row {
  margin: 0px;
}

.routes-card .row div {
  padding: 0px;
}

.routes-driver {
  font-size: 15px;
  font-weight: 600;
  word-break: break-word;
}

.routes-secundary-info {
  padding-top: 5px;
}

.routes-text {
  font-size: 12px;
  word-break: break-word;
}

.route-spot-icon {
  margin-right: 5px;
}

.route-green {
  color: green;
}

.route-yellow {
  color: #c4c443;
}

.route-red {
  color: red;
}

.route-blue {
  color: blue;
}

.route-spot-panel {
  background-color: #ffffff;
  border-radius: 0px 10px 10px 0px;
  width: 25%;
  height: calc(100vh - 86px);
  float: left;
  position: absolute;
  left: 25%;
  z-index: 10;
}

.draw-on-map {
  border-bottom: 1.5px solid rgba(232, 102, 47, 0.7);
  text-align: center;
}

.draw-on-map i {
  font-size: 14px;
}

.route-spot-timeline {
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 318px);
}

.route-spot-timeline-prevision {
  font-size: 11px !important;
}

.close-route-spot {
  border-bottom: 1.5px solid rgba(232, 102, 47, 0.7);
  padding: 0 5px;
  font-size: 15px;
  z-index: 10;
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 100px;
}

.close-route-spot>div {
  text-align: center;
}

.close-route-spot i {
  font-size: 14px;
}

.close-route-spot p {
  font-size: 13px;
  margin: 0;
}

.status-info-detail {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.status-info-detail p {
  margin-left: 3px;
  display: inline;
}

.status-info-detail:nth-child(1),
.status-info-detail:nth-child(2),
.status-info-detail:nth-child(3),
.status-info-detail:nth-child(4) {
  width: 32%;
}


.status-info-detail:nth-child(5) {
  width: 4%;
}
.close-detail-button {
  cursor: pointer;
}

.close-detail-button i {
  font-size: 15px;
}

.bubble-style {
  background-color: red;
}

.maps-panel {
  width: 75%;
  height: 100%;
  float: right;
  position: relative;
  z-index: 2;
}

.maps-panel img {
  width: 100%;
  height: 100%;
}

.truck-marker {
  font-size: 25px;
}

.map-marker-img {
  width: 45px !important;
  z-index: 500;
}

.map-loading {
  position: fixed;
  width: 105%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 150px;
  background-color: #a9a9a9b3;
  z-index: 1040;
}

.card-chart {
  max-width: 800px;
  margin: auto;
}

@media (max-width: 768px) {
  .maps-panel {
    width: 100%;
    height: 70%;
  }
  .rotas-panel {
    width: 100%;
    height: 32%;
  }
  .route-spot-panel {
    width: 100%;
    height: 23.5%;
    top: 66.5%;
    left: 0;
    overflow: hidden;
  }
  .card-chart {
    max-width: 250px;
    margin: auto;
  }
  .route-spot-timeline {
    height: 69%;
  }
}

.marker-tool-tip {
  position: relative;
  z-index: 1000;
  display: inline-block;
  padding: 5px;
  background-color: #787878;
  border-radius: 3px;
  white-space: nowrap;
}

.marker-tool-tip p {
  display: inline-block;
  color: white;
  font-size: 11px;
  font-weight: 900;
  margin-bottom: 3px;
}

.timeline-content>span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-content>div>div>span {
  text-align: center!important;
}

.timeline-content>div>span>i {
  font-size: 12px;
}

.vertical-timeline::before {
  background: #3c3c3b!important;
}

.timeline-content h3, .timeline-content h4, .timeline-content > span {
  font-size: 12px;
  background-color: #fff;
  margin: 0px;
}

.vertical-timeline-element-icon {
  width: 40px!important;
  height: 40px!important;
  box-shadow: none!important;
  border: 2px solid #3c3c3b;
  z-index: 2;
}

.vertical-timeline-element-content {
  padding: 0!important;
  box-shadow: none!important;
}

/* fix for timeline component */

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 1em 0!important;
  }
  .element-timeline-selectable {
    height: 77px;
  }
  .vertical-timeline::before {
    margin-left: -10px!important;
  }
  .vertical-timeline-element-icon {
    margin-left: -28px!important;
  }
  .vertical-timeline-element-date {
    padding: 0!important;
  }
}

/* CUSTOM SCROOL BAR ROUTE CONTENT*/

.routes-content::-webkit-scrollbar {
  width: 6px;
  background-color: rgb(255, 255, 255);
}

.routes-content::-webkit-scrollbar-thumb {
  background-color: rgba(60, 60, 59, 0.5);
  border-radius: 10px;
  visibility: hidden;
}

.routes-content:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/* CUSTOM SCROOL BAR TIMELINE*/

.route-spot-timeline::-webkit-scrollbar {
  width: 6px;
  background-color: rgb(255, 255, 255);
}

.route-spot-timeline::-webkit-scrollbar-thumb {
  background-color: rgba(60, 60, 59, 0.5);
  border-radius: 10px;
}

.element-timeline-selectable {
  height: auto;
  min-height: 55px;
}
.vertical-timeline-element-icon, .vertical-timeline-element-title {
  cursor: pointer;
}
/* TIMELINE RESPONSIVE */

@media only screen and (min-width: 769px) and (max-width: 1241px) {
  .close-route-spot p {
    font-size: 10px;
  }
  .status-info-detail p {
    margin-left: 0px;
  }
  .close-route-spot {
    padding: 0 2px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .status-info-detail:nth-child(1), .status-info-detail:nth-child(2), .status-info-detail:nth-child(3) {
    display: flex;
    flex-direction: column
  }
  .status-info p {
    margin: 0px;
  }
}

.status-info:nth-child(1), .status-info:nth-child(2), .status-info:nth-child(3), .status-info:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 33%;
}

.status-content>div>p {
  margin: 0;
}

/* date */

.vertical-timeline-element-date {
  font-size: 0.7rem!important;
  right: 141%!important;
  font-weight: 900!important;
}

.vertical-timeline-element-title {
  font-weight: 600;
  opacity: 0.7;
}

.vertical-timeline-element-delivery-date {
  font-weight: bold;
  font-size: 11px!important;
}

.routes-search {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 80%;
  position: relative;
}

.routes-search>i {
  position: absolute;
  right: 0;
  padding: 8px;
}

.routes-search>i:hover {
  cursor: pointer;
}

.routes-search>input {
  padding-right: 30px;
}

@media only screen and (min-width: 769px) and (max-width: 1072px) {
  .routes-status p {
    font-size: 8px;
  }
}

.bg-one-routes-card {
  background-color: #4d4d4c;
}

.bg-two-routes-card {
  background-color: #3c3c3b;
}

.route-details-open {
  border-radius: 0px 0px!important;
  border: solid 2px rgba(232, 102, 47, 0.7)!important
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.route-spot-container {
  position: relative;
  z-index: 4;
}

.leaflet-div-icon {
  border: none;
  background: none;
}

.routes-span-subinfo {
  font-weight: 500;
}

.bg-green {
  background-color: green;
}

.bg-red {
  background-color: red;
}

.bg-blue {
  background-color: blue;
}

.bg-yellow {
  background-color: yellow;
}

.route-not-started {
  background-color: #cdcdcd;
}

.rotas-header-tabs {
  height: 32%;
  width: 70%;
  display: flex;
  align-items: flex-end;
  flex-direction: initial;
  justify-content: center;
}

.rotas-header-tabs>button:nth-child(1) {
  background-color: #EC6730;
  border-radius: 15px 0px 0px 0px;
  border: 0;
  width: 50%;
  height: 100%;
  font-size: 0.9rem;
}
.rotas-header-tabs>button:nth-child(2) {
  background-color: #EC6730;
  border-radius: 0px 15px 0px 0px;
  border: 0;
  width: 50%;
  height: 100%;
  font-size: 0.9rem;
}

.rotas-header-tabs>button:nth-child(1):hover,.rotas-header-tabs>button:nth-child(2):hover {
  background-color: #f0895f;
  opacity: 1;
}
.rotas-header-tabs>button:nth-child(2){
  border-left: 1px solid #cdcdcd;
}

.not-selected {
  opacity: 0.6;
}

.custom-disabled,.custom-disabled:hover {
  background-color: #EC6730!important;
  opacity: 0.6!important;
  color: black;
}

button:focus {
  outline: 0;
}

.recipient-person{
  font-weight: 600;
}

.footer-painel-rotas {
  background-color: rgba(205, 205, 205, 0.2);
  width: 100%;
  padding: 5px;
}

.footer-painel-rotas:hover {
  background-color: rgba(205, 205, 205, 0.2)!important;
  cursor: default;
}

.footer-spot-timeline {
  height: 35px;
  text-align: center;
  background-color:  rgba(205, 205, 205, 0.2);
  border-radius: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-spot-timeline > p {
  margin: 0;
}

.active-route-card {
  background-color: rgba(232, 102, 47, 0.5)!important
}

.menu-filter-active {
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
}

.tab-active {
  background: rgba(232, 102, 47, 0.5);
  height: 5px;
  width: 33%;
  position: relative;
  transition-property: left;
  transition-duration: 0.8s;
}

.close-filter-status-active {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  min-height: 15px;
}

.filter-status-active {
  background: rgba(232, 102, 47, 0.1);
  border-radius: 13px;
}

.custom-popup-truck {
  width: 100%;
}

.container-popup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.driver-info {
  display: flex;
  flex-direction: row
}

.driver-image , .driver-image > img {
  width: 50px;
  border-radius: 50%;
}

.driver-data {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.travel-info > div:nth-child(1), .travel-info  > div:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.driver-times {
  display: flex;
  flex-direction: row;
}

.driver-times > div {
  width: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.charge-info {
  display: flex;
  flex-direction: row;
}

.charge-info > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.leaflet-map-pane {
  width: 38%;
}

.leaflet-popup-pane, .custom-popup-truck > .leaflet-popup-content-wrapper > div  {
  width: 100%!important;
}

/* Custom size of delivery popup (to use on all popups change to .leaflet-popup-content-wrapper) */
.delivery-popup > .leaflet-popup-content-wrapper { 
  /* width: calc(50vw - 65px); */
  min-width: 529px;
  padding: 20px;
}

.txt-title {
  font-size: 1.1rem;
}

.txt-info {
  font-size: 0.8rem;
  white-space: nowrap;
}

/* fix margin popup */
.leaflet-popup-content {
  margin: 0!important;
  padding: 10px;
}

.globe-icon-card-route {
  background: #50b57d;
  color: #fff;
  font-size: 18px;
}

.globe-icon-card-route:disabled {
  background: #dddddd;
}

.custom-col-sequence-ponto {
  padding: 5px;
}

.delivery-info {
  display: flex;
  flex-direction: row;
}

.delivery-info > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.delivery-popup-block {
  margin-right: 5px;
}

.dot {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.address-block {
  min-width: 200px;
}

.width-100p {
  width: 100%;
}

.h2-route {
  margin: 0px !important;
}

.map-popup-latlng >span {
  color: #0089a5;
  font-size: 16px;
  font-weight: bold;
}